<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h2 v-if="artist.name">{{ artist.name }}</h2>
          <h2 v-else>New Artist</h2>
        </div>
      </div>
      <hr />

      <div class="col-xl-6">
        <form class="needs-validation" novalidate>
          <div class="mb-3">
            <label for="release_title">Name</label>

            <input
              v-model="artist.name"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !artist.name,
                'is-valid': artist.name,
              }"
            />
            <div class="invalid-feedback">Please enter a valid name.</div>
          </div>

          <div v-if="!show_new_org" class="form-group mb-3">
            <label class="form-label"> Org </label>

            <div class="row">
              <div class="col-xl-10">
                <v-select
                  v-model="artist.orgs[0]"
                  :options="display_orgs"
                  :selectOnTab="true"
                  :clearable="true"
                ></v-select>
              </div>
              <div class="col-xl-2">
                <button
                  @click="show_new_org = true"
                  class="btn btn-sm btn-outline-secondary ms-2"
                >
                  new
                </button>
              </div>
            </div>
            <div class="invalid-feedback">Please select a valid org.</div>
          </div>

          <OrgCreateNew
            v-if="show_new_org"
            @select_new_org="select_new_org"
            @close_new_org="close_new_org"
          ></OrgCreateNew>

          <label for="release_title" class="form-label">Apple Id</label>
          <div class="input-group mb-1">
            <input
              v-model="artist.apple_id"
              type="text"
              class="form-control"
              :class="{
                'is-valid': artist.apple_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
            <span class="input-group-text">Create New</span>
            <span class="input-group-text">
              <input
                v-model="artist.is_apple_create_new"
                class="form-check-input"
                type="checkbox"
            /></span>
          </div>

          <label for="release_title" class="form-label">Spotify Id</label>
          <div class="input-group mb-1">
            <input
              v-model="artist.spotify_id"
              type="text"
              class="form-control"
              :class="{
                'is-valid': artist.spotify_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
            <span class="input-group-text">Create New</span>
            <span class="input-group-text">
              <input
                v-model="artist.is_spotify_create_new"
                class="form-check-input"
                type="checkbox"
            /></span>
          </div>

          <!-- <div class="mb-3">
            <label for="release_title">Apple Id</label>

            <input
              v-model="artist.apple_id"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !artist.apple_id,
                'is-valid': artist.apple_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
          </div>

          <div class="mb-3">
            <label for="release_title">Spotify Id</label>

            <input
              v-model="artist.spotify_id"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !artist.spotify_id,
                'is-valid': artist.spotify_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
          </div> -->
          <div class="form-group mb-3">
            <label for="social_media_links" class="form-label">
              Artist's website &amp; social media links</label
            >

            <div class="card">
              <div class="card-body p-3">
                <div v-for="(link, i) in artist.social_media_links" :key="i">
                  <div class="row mb-3">
                    <div class="col">
                      <div class="input-group">
                        <input
                          v-model="link.url"
                          type="url"
                          class="form-control"
                          :placeholder="`link (${i + 1})`"
                        />
                        <div class="input-group-append">
                          <button
                            @click.prevent="
                              add_line(artist.social_media_links, i)
                            "
                            class="btn btn-outline-success"
                            type="button"
                            title="add another link"
                          >
                            +
                          </button>
                          <button
                            @click.prevent="
                              delete_line(artist.social_media_links, i)
                            "
                            class="btn btn-outline-danger"
                            type="button"
                            title="remove this link"
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="tier"> Tier</label>

            <select
              v-model="artist.tier"
              class="form-select d-block w-100 m-1"
              id="country"
              :class="{
                'is-invalid': submitted && !artist.tier,
                'is-valid': artist.tier && artist.tier,
              }"
            >
              <option>Tier 1</option>
              <option>Tier 2</option>
              <option>Tier 3</option>
            </select>
            <div class="invalid-feedback">Please select a tier.</div>
          </div>
          <button
            @click.prevent="create_artist"
            type="submit"
            class="btn btn-secondary"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </basicLayout>
</template>

<style></style>

<script>
import basicLayout from "@/components/basicLayout";
import OrgCreateNew from "@/components/OrgCreateNew";
import { state } from "@/store";
import { orderBy } from "lodash";
import { db, fb } from "@/firebaseConfig";

export default {
  name: "createArtist",
  data: function () {
    return {
      artist: {
        social_media_links: [{ url: "" }],
        orgs: [{ name: "", label: "" }],
        org_ids: [],
      },
      orgs: [{ name: "", label: "" }],
      submitted: false,
      show_new_org: false,
    };
  },

  components: {
    basicLayout,
    OrgCreateNew,
  },

  firestore() {
    return {
      orgs: db.collection("orgs").orderBy("name"),
    };
  },

  methods: {
    add_artist_to_fuga: async function () {
      const fuga_artist_upsert = fb.httpsCallable("fuga_artist_upsert");
      const fuga_res = await fuga_artist_upsert(this.artist);

      console.log(fuga_res);

      const artist_fuga_id = fuga_res.data.fuga_id;

      return artist_fuga_id;
    },

    create_artist: async function () {
      this.submitted = true;
      let artist = this.artist;

      const artist_org_ids = artist.orgs.map((o) => o.id);

      // const artist_fuga_id = await this.add_artist_to_fuga();

      // console.log("artist_fuga_id: " + artist_fuga_id);

      if (!artist.name) {
        return;
      }

      if (this.show_new_org || this.artist.orgs[0].id === "new_org_id") {
        this.$toast.warning("Please select a valid organisation", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      const new_artist_object = {
        name: artist.name || "",
        orgs: artist.orgs || [],
        org_ids: artist_org_ids || [],
        apple_id: artist.apple_id || "",
        spotify_id: artist.spotify_id || "",
        is_apple_create_new: artist.is_apple_create_new || false,
        is_spotify_create_new: artist.is_spotify_create_new || false,
        tier: artist.tier || "Tier 3",
        date_created: new Date(),
        created_by: this.userProfile.name || "",
        fuga_id: "",
        is_active: true,
      };

      db.collection("artists").add(new_artist_object);
      this.$router.push("/artists");
    },
    select_new_org(org_id) {
      console.log(org_id);
      let new_org_select = this.orgs.find((o) => o.id === org_id);
      this.artist.orgs[0] = {
        id: new_org_select.id,
        label: new_org_select.name,
        ...new_org_select,
      };
      this.show_new_org = false;
    },

    close_new_org() {
      this.show_new_org = false;
      // this.artist.org = {};
    },
  },

  computed: {
    display_orgs: function () {
      let orgs = this.orgs.slice();

      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };

        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },

    userProfile() {
      return state.userProfile;
    },
  },

  watch: {
    "artist.org"(selected_org) {
      if (selected_org && selected_org.id === "new_org_id") {
        this.show_new_org = true;
        selected_org = {};
      } else {
        this.show_new_org = false;
      }
    },
  },
};
</script>
