<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">Artist Profile</h6>
          <h2 v-if="artist.name">{{ artist.name }}</h2>
          <h2 v-else>Artist</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-6">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <form class="needs-validation" novalidate>
                    <!-- <button @click.prevent="generate_upc">generate_upc</button> -->
                    <div class="mb-3">
                      <label for="release_title" class="form-label">Name</label>

                      <input
                        v-model="artist.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': !artist.name,
                          'is-valid': artist.name,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid name.
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="release_title" @dblclick="edit_fuga_id"
                        >FUGA Id</label
                      >

                      <input
                        v-model="artist.fuga_id"
                        type="text"
                        class="form-control"
                        @dblclick="edit_fuga_id"
                        :disabled="!is_editing_fuga_id"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                    </div>

                    <label for="release_title" class="form-label"
                      >Apple Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="artist.apple_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !artist.apple_id,
                          'is-valid': artist.apple_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="artist.is_apple_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div>

                    <label for="release_title" class="form-label"
                      >Spotify Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="artist.spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !artist.spotify_id,
                          'is-valid': artist.spotify_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="artist.is_spotify_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div>

                    <!-- <div class="mb-3">
                      <label for="release_title">Apple Id</label>

                      <input
                        v-model="artist.apple_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !artist.apple_id,
                          'is-valid': artist.apple_id,
                        }" />
                      <div class="invalid-feedback">Please enter a valid ID.</div>
                    </div>

                    <div class="mb-3">
                      <label for="release_title">Spotify Id</label>

                      <input
                        v-model="artist.spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && !artist.spotify_id,
                          'is-valid': artist.spotify_id,
                        }" />
                      <div class="invalid-feedback">Please enter a valid ID.</div>
                    </div> -->

                    <div class="form-group mb-3">
                      <label for="tier"> Tier</label>

                      <select
                        v-model="artist.tier"
                        class="form-select d-block w-100 m-1"
                        id="country"
                        :class="{
                          'is-invalid': submitted && !artist.tier,
                          'is-valid': artist.tier && artist.tier,
                        }"
                      >
                        <option>Tier 1</option>
                        <option>Tier 2</option>
                        <option>Tier 3</option>
                      </select>
                      <div class="invalid-feedback">Please select a tier.</div>
                    </div>

                    <div class="form-group mb-3">
                      <label for="social_media_links" class="form-label">
                        Artist's website &amp; social media links</label
                      >

                      <div class="card">
                        <div class="card-body p-3">
                          <div
                            v-for="(link, i) in artist.social_media_links"
                            :key="i"
                          >
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group">
                                  <input
                                    v-model="link.url"
                                    type="url"
                                    class="form-control"
                                    :placeholder="`link (${i + 1})`"
                                  />
                                  <div class="input-group-append">
                                    <button
                                      @click.prevent="
                                        add_line(artist.social_media_links, i)
                                      "
                                      class="btn btn-outline-success"
                                      type="button"
                                      title="add another link"
                                    >
                                      +
                                    </button>
                                    <button
                                      @click.prevent="
                                        delete_line(
                                          artist.social_media_links,
                                          i
                                        )
                                      "
                                      class="btn btn-outline-danger"
                                      type="button"
                                      title="remove this link"
                                    >
                                      -
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-3">
                        <label class="form-label"> Add Connected Org </label>

                        <v-select
                          v-model="selected_org"
                          :options="display_orgs"
                          :selectOnTab="true"
                          :clearable="true"
                        ></v-select>
                        <div class="invalid-feedback">
                          Please select a valid org.
                        </div>
                      </div>

                      <div
                        v-if="artist.orgs.length"
                        class="card border-success m-2"
                      >
                        <div class="card-header"><h6>CONNECTED ORGS</h6></div>
                        <div class="card-body">
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="w-30" scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th class="text-center w-5" scope="col"></th>
                                <th class="text-center w-5" scope="col">
                                  Remove
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="org in artist.orgs" :key="org.id">
                                <td>{{ org.name }}</td>
                                <td>{{ org.description }}</td>
                                <td>
                                  <router-link
                                    :to="{
                                      name: 'OrgProfile',
                                      params: { org_id: org.id },
                                    }"
                                    title="edit org"
                                  >
                                    <edit-icon
                                      size="1x"
                                      class="text-success"
                                    ></edit-icon>
                                  </router-link>
                                </td>
                                <td class="text-center">
                                  <x-circle-icon
                                    @click="remove_org(org)"
                                    role="button"
                                    size="1.0x"
                                    class="text-danger"
                                  ></x-circle-icon>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <button
                          @click.prevent="save_artist"
                          type="submit"
                          class="btn btn-outline-success"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col text-end">
                        <button
                          @click.prevent="delete_artist()"
                          type="submit"
                          class="btn btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header">
                  <h4>{{ artist.name }}</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Name</div>
                        {{ artist.name }}
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="artist.fuga_id" class="fw-bold">
                          FUGA Id
                        </div>
                        <a :href="fuga_url(artist.fuga_id)" target="_blank">{{
                          artist.fuga_id
                        }}</a>
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="artist.apple_id" class="fw-bold">
                          Apple ID
                        </div>
                        <a :href="apple_url(artist.apple_id)" target="_blank">{{
                          url_id(artist.apple_id)
                        }}</a>
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="artist.spotify_id" class="fw-bold">
                          Spotify ID
                        </div>
                        <a
                          :href="spotify_url(artist.spotify_id)"
                          target="_blank"
                          >{{ url_id(artist.spotify_id) }}</a
                        >
                      </div>
                    </li>

                    <li
                      v-for="(link, i) in artist.social_media_links"
                      :key="link.url"
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div v-if="i == 0" class="fw-bold">Social media</div>
                        <facebook-icon
                          v-if="link_icon(link, 'facebook')"
                          size="1.2x"
                          class="me-2"
                        ></facebook-icon>
                        <instagram-icon
                          v-else-if="link_icon(link, 'instagram')"
                          size="1.2x"
                          class="me-2"
                        ></instagram-icon>
                        <youtube-icon
                          v-else-if="link_icon(link, 'youtube')"
                          size="1.2x"
                          class="me-2"
                        ></youtube-icon>
                        <link-icon v-else size="1.0x" class="me-2"></link-icon>
                        <a :href="link.url" target="_blank">{{
                          link_hostname(link.url)
                        }}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col"><ArtistContracts :artist="this.artist" /></div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <ArtistContractUpload :artist_id="this.artist.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import ArtistContracts from "@/components/ArtistContractsCopy";
import ArtistContractUpload from "@/components/ArtistContractUploadCopy";
import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkIcon,
  FileTextIcon,
  XCircleIcon,
  EditIcon,
} from "vue-feather-icons";
import { orderBy } from "lodash";

export default {
  name: "artistProfile",
  data() {
    return {
      artist: {
        social_media_links: [{ url: "" }],
        orgs: [{ name: "", label: "" }],
        org_ids: [],
      },
      orgs: [{ name: "", label: "" }],
      selected_org: { name: "", label: "" },
      roles: ["artist", "agent", "admin"],
      submitted: false,
      is_editing_fuga_id: false,
    };
  },
  firestore() {
    return {
      artist: db.collection("artists").doc(this.$route.params.artist_id),
      orgs: db.collection("orgs").orderBy("name"),
    };
  },
  components: {
    basicLayout,
    ArtistContracts,
    ArtistContractUpload,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
    FileTextIcon,
    XCircleIcon,
    EditIcon,
  },

  methods: {
    edit_fuga_id: function () {
      console.log(this.is_editing_fuga_id);
      this.is_editing_fuga_id = !this.is_editing_fuga_id;
    },

    save_artist: async function () {
      // console.log(this.artist.id);
      // await this.update_artist_on_fuga();

      console.log(this.artist);

      // TODO: UPDATE APPLE AND SPOTIFY ID ON FUGA

      db.collection("artists").doc(this.artist.id).set(this.artist);
      this.$router.push("/artists");
    },

    update_artist_on_fuga: async function () {
      console.log("update_artist_on_fuga");

      let artist = this.artist;

      const fuga_artist_upsert = fb.httpsCallable("fuga_artist_upsert");
      const fuga_res = await fuga_artist_upsert(artist);

      console.log(fuga_res);

      if (artist.fuga_id) {
        this.artist.fuga_id = fuga_res.data?.success && fuga_res.data?.fuga_id;
      }
    },

    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        // console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },

    fuga_url(id) {
      return `https://next.fugamusic.com/artist/${id}`;
    },

    apple_url(id) {
      if (id && !id.includes("https://music.apple.com/")) {
        return `https://music.apple.com/artist/${id}`;
      } else {
        return id;
      }
    },

    spotify_url(id) {
      if (id && !id.includes("https://open.spotify.com/artist/")) {
        return `https://open.spotify.com/artist/${id}`;
      } else {
        return id;
      }
    },

    url_id(url) {
      // console.log(url);
      try {
        let new_url = url && new URL(url);
        let id = new_url && new_url.pathname.split("/").reverse()[0];
        return id;
      } catch (e) {
        // console.log(e);
        return url;
      }
    },
    add_line: function (items, i) {
      // console.log("i: " + i);

      items.splice(i + 1, 0, {});
    },
    delete_line(items, i) {
      if (items.length === 1) {
        return false;
      }
      items.splice(i, 1);
    },
    remove_org(org) {
      // console.log(org);
      const new_org_list = this.artist.orgs.filter((o) => o.id !== org.id);
      const new_org_ids_list = this.artist.org_ids.filter(
        (oid) => oid !== org.id
      );
      // console.log(new_org_list);
      this.artist.orgs = new_org_list;
      this.artist.org_ids = new_org_ids_list;
    },
    delete_artist() {
      console.log(this.artist.id);
      db.collection("artists").doc(this.artist.id).update({ is_active: false });
      this.$router.push("/artists");
    },
  },

  computed: {
    display_orgs: function () {
      let orgs = this.orgs.slice();
      orgs = orgs.map((o) => {
        let display_org = {
          id: o.id,
          label: `${o.name} (${o.royalties_name_code})`,
          name: o.name,
          description: o.description,
          royalties_name_code: o.royalties_name_code,
        };
        return display_org;
      });
      orgs = orderBy(orgs, "name", "asc");
      return orgs;
    },
  },
  watch: {
    selected_org(new_org) {
      if (!this.artist.orgs) {
        this.artist.orgs = [];
      }
      if (!this.artist.org_ids) {
        this.artist.org_ids = [];
      }
      if (new_org.id) {
        this.artist.orgs.push(new_org);
        this.artist.org_ids.push(new_org.id);
      }
      this.new_org = false;
      new_org = { id: "" };
    },
  },
};
</script>
