<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h6 class="text-muted text-uppercase">Person Profile</h6>
          <h2 v-if="person.name">{{ person.name }}</h2>
          <h2 v-else>Person</h2>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-xl-6">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <form class="needs-validation" novalidate>
                    <!-- <button @click.prevent="generate_upc">generate_upc</button> -->
                    <div class="mb-3">
                      <label for="release_title" class="form-label">Name</label>
                      <input
                        v-model="person.name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': !person.name,
                          'is-valid': person.name,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid name.
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="release_title" class="form-label"
                        >FUGA Id</label
                      >
                      <input
                        v-model="person.fuga_id"
                        type="text"
                        class="form-control"
                        disabled
                      />
                      <div class="invalid-feedback">
                        Please enter a valid fuga_id.
                      </div>
                    </div>

                    <label for="release_title" class="form-label"
                      >Apple Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="person.apple_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': person.apple_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="person.is_apple_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div>

                    <label for="release_title" class="form-label"
                      >Spotify Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="person.spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': person.spotify_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="person.is_spotify_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div>

                    <!-- <label for="release_title" class="form-label"
                      >Apple Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="person.apple_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': person.apple_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="person.is_apple_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div>

                    <label for="release_title" class="form-label"
                      >Spotify Id</label
                    >
                    <div class="input-group mb-1">
                      <input
                        v-model="person.spotify_id"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-valid': person.spotify_id,
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid ID.
                      </div>
                      <span class="input-group-text">Create New</span>
                      <span class="input-group-text">
                        <input
                          v-model="person.is_spotify_create_new"
                          class="form-check-input"
                          type="checkbox"
                      /></span>
                    </div> -->

                    <div class="form-group mb-3">
                      <label for="social_media_links" class="form-label">
                        Website &amp; social media links</label
                      >

                      <div class="card">
                        <div class="card-body p-3">
                          <div
                            v-for="(link, i) in person.social_media_links"
                            :key="i"
                          >
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group">
                                  <input
                                    v-model="link.url"
                                    type="url"
                                    class="form-control"
                                    :placeholder="`link (${i + 1})`"
                                  />
                                  <div class="input-group-append">
                                    <button
                                      @click.prevent="
                                        add_line(person.social_media_links, i)
                                      "
                                      class="btn btn-outline-success"
                                      type="button"
                                      title="add another link"
                                    >
                                      +
                                    </button>
                                    <button
                                      @click.prevent="
                                        delete_line(
                                          person.social_media_links,
                                          i
                                        )
                                      "
                                      class="btn btn-outline-danger"
                                      type="button"
                                      title="remove this link"
                                    >
                                      -
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <button
                          @click.prevent="save_person"
                          type="submit"
                          class="btn btn-outline-success"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col text-end">
                        <button
                          @click.prevent="delete_person()"
                          type="submit"
                          class="btn btn-outline-danger"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header">
                  <h4>{{ person.name }}</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Name</div>
                        {{ person.name }}
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="person.fuga_id" class="fw-bold">
                          FUGA Id
                        </div>
                        <a :href="fuga_url(person.fuga_id)" target="_blank">{{
                          person.fuga_id
                        }}</a>
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="person.apple_id" class="fw-bold">
                          Apple ID
                        </div>
                        <a :href="apple_url(person.apple_id)" target="_blank">{{
                          url_id(person.apple_id)
                        }}</a>
                      </div>
                    </li>

                    <li
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div :title="person.spotify_id" class="fw-bold">
                          Spotify ID
                        </div>
                        <a
                          :href="spotify_url(person.spotify_id)"
                          target="_blank"
                          >{{ url_id(person.spotify_id) }}</a
                        >
                      </div>
                    </li>

                    <li
                      v-for="(link, i) in person.social_media_links"
                      :key="link.url"
                      class="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div class="ms-2 me-auto">
                        <div v-if="i == 0" class="fw-bold">Social media</div>
                        <facebook-icon
                          v-if="link_icon(link, 'facebook')"
                          size="1.2x"
                          class="me-2"
                        ></facebook-icon>
                        <instagram-icon
                          v-else-if="link_icon(link, 'instagram')"
                          size="1.2x"
                          class="me-2"
                        ></instagram-icon>
                        <youtube-icon
                          v-else-if="link_icon(link, 'youtube')"
                          size="1.2x"
                          class="me-2"
                        ></youtube-icon>
                        <link-icon v-else size="1.0x" class="me-2"></link-icon>
                        <a :href="link.url" target="_blank">{{
                          link_hostname(link.url)
                        }}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";

import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkIcon,
} from "vue-feather-icons";
export default {
  name: "personProfile",
  data() {
    return {
      person: { social_media_links: [{ url: "" }] },
      roles: ["person", "agent", "admin"],
      submitted: false,
    };
  },
  firestore() {
    return {
      person: db.collection("people").doc(this.$route.params.person_id),
    };
  },
  components: {
    basicLayout,
    FacebookIcon,
    InstagramIcon,
    YoutubeIcon,
    LinkIcon,
  },

  methods: {
    save_person: async function () {
      // await this.update_person_on_fuga();

      // TODO: UPDATE APPLE AND SPOTIFY ID ON FUGA

      // console.log(this.person.id);
      db.collection("people").doc(this.person.id).set(this.person);
      this.$router.push("/people");
    },

    update_person_on_fuga: async function () {
      console.log("update_person_on_fuga");

      const fuga_person_upsert = fb.httpsCallable("fuga_person_upsert");
      const fuga_res = await fuga_person_upsert(this.person);

      console.log(fuga_res);
    },

    link_hostname(link) {
      try {
        const url = link && new URL(link);
        return (url && url.hostname) || "";
      } catch (e) {
        // console.log(e);
        return "";
      }
    },
    link_icon(link, domain) {
      const hostname = link && this.link_hostname(link.url);
      return hostname && hostname.includes(domain);
    },

    fuga_url(id) {
      return `https://next.fugamusic.com/person/${id}`;
    },

    apple_url(id) {
      if (id && !id.includes("https://music.apple.com/")) {
        return `https://music.apple.com/person/${id}`;
      } else {
        return id;
      }
    },

    spotify_url(id) {
      if (id && !id.includes("https://open.spotify.com/person/")) {
        return `https://open.spotify.com/person/${id}`;
      } else {
        return id;
      }
    },

    url_id(url) {
      // console.log(url);
      try {
        let new_url = url && new URL(url);
        let id = new_url && new_url.pathname.split("/").reverse()[0];
        return id;
      } catch (e) {
        // console.log(e);
        return url;
      }
    },
    add_line: function (items, i) {
      // console.log("i: " + i);

      items.splice(i + 1, 0, {});
    },
    delete_line(items, i) {
      if (items.length === 1) {
        return false;
      }
      items.splice(i, 1);
    },

    delete_person() {
      console.log(this.person.id);
      db.collection("people").doc(this.person.id).update({ is_active: false });
      this.$router.push("/people");
    },
  },

  computed: {},
  watch: {},
};
</script>
