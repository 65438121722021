<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h2 v-if="person.name">{{ person.name }}</h2>
          <h2 v-else>New Person</h2>
        </div>
      </div>
      <hr />

      <div class="col-xl-6">
        <form class="needs-validation" novalidate>
          <div class="mb-3">
            <label for="release_title">Name</label>

            <input
              v-model="person.name"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !person.name,
                'is-valid': person.name,
              }"
            />
            <div class="invalid-feedback">Please enter a valid name.</div>
          </div>

          <label for="release_title" class="form-label">Apple Id</label>
          <div class="input-group mb-1">
            <input
              v-model="person.apple_id"
              type="text"
              class="form-control"
              :class="{
                'is-valid': person.apple_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
            <span class="input-group-text">Create New</span>
            <span class="input-group-text">
              <input
                v-model="person.is_apple_create_new"
                class="form-check-input"
                type="checkbox"
            /></span>
          </div>

          <label for="release_title" class="form-label">Spotify Id</label>
          <div class="input-group mb-1">
            <input
              v-model="person.spotify_id"
              type="text"
              class="form-control"
              :class="{
                'is-valid': person.spotify_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
            <span class="input-group-text">Create New</span>
            <span class="input-group-text">
              <input
                v-model="person.is_spotify_create_new"
                class="form-check-input"
                type="checkbox"
            /></span>
          </div>

          <!-- <div class="mb-3">
            <label for="release_title">Apple Id</label>

            <input
              v-model="person.apple_id"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !person.apple_id,
                'is-valid': person.apple_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
          </div>

          <div class="mb-3">
            <label for="release_title">Spotify Id</label>

            <input
              v-model="person.spotify_id"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !person.spotify_id,
                'is-valid': person.spotify_id,
              }"
            />
            <div class="invalid-feedback">Please enter a valid ID.</div>
          </div> -->
          <div class="form-group mb-3">
            <label for="social_media_links" class="form-label">
              Artist's website &amp; social media links</label
            >

            <div class="card">
              <div class="card-body p-3">
                <div v-for="(link, i) in person.social_media_links" :key="i">
                  <div class="row mb-3">
                    <div class="col">
                      <div class="input-group">
                        <input
                          v-model="link.url"
                          type="url"
                          class="form-control"
                          :placeholder="`link (${i + 1})`"
                        />
                        <div class="input-group-append">
                          <button
                            @click.prevent="
                              add_line(person.social_media_links, i)
                            "
                            class="btn btn-outline-success"
                            type="button"
                            title="add another link"
                          >
                            +
                          </button>
                          <button
                            @click.prevent="
                              delete_line(person.social_media_links, i)
                            "
                            class="btn btn-outline-danger"
                            type="button"
                            title="remove this link"
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            @click.prevent="create_person"
            type="submit"
            class="btn btn-secondary"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </basicLayout>
</template>

<style></style>

<script>
import basicLayout from "@/components/basicLayout";

import { state } from "@/store";
// import { orderBy } from "lodash";
import { db, fb } from "@/firebaseConfig";

export default {
  name: "createPerson",
  data: function () {
    return {
      person: {
        social_media_links: [{ url: "" }],
        orgs: [{ name: "", label: "" }],
        org_ids: [],
      },
      orgs: [{ name: "", label: "" }],
      submitted: false,
    };
  },

  components: {
    basicLayout,
  },

  methods: {
    add_person_to_fuga: async function () {
      console.log("add_person_to_fuga");

      const fuga_person_upsert = fb.httpsCallable("fuga_person_upsert");
      const fuga_res = await fuga_person_upsert(this.person);

      console.log(fuga_res);

      const person_fuga_id = fuga_res.data.fuga_id;

      return person_fuga_id;
    },

    create_person: async function () {
      this.submitted = true;
      let person = this.person;

      // console.log("getting fuga_id");

      // const person_fuga_id = await this.add_person_to_fuga();

      // if (!person.name || !person_fuga_id) {
      //   console.log("NO FUGA ID!");
      //   return;
      // }

      console.log("person_fuga_id: " + person_fuga_id);

      const new_person_object = {
        name: person.name || "",
        // fuga_id: Number(person_fuga_id),
        apple_id: person.apple_id || "",
        spotify_id: person.spotify_id || "",
        is_apple_create_new: person.is_apple_create_new || false,
        is_spotify_create_new: person.is_spotify_create_new || false,
        date_created: new Date(),
        created_by: this.userProfile.name || "",
        is_active: true,
      };
      console.log("--------------------");
      console.log(new_person_object);
      console.log("--------------------");

      db.collection("people").add(new_person_object);
      this.$router.push("/people");
    },
  },

  computed: {
    userProfile() {
      return state.userProfile;
    },
  },
};
</script>
