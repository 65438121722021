<template>
  <div class="card border-success m-3">
    <div class="card-header"><h6>NEW ORG</h6></div>
    <div class="card-body">
      <div class="mb-3">
        <label for="release_title" class="form-label">Name</label>

        <input
          v-model="org.name"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': org_submitted && !org.name,
            'is-valid': org.name,
          }"
        />
        <div class="invalid-feedback">Please enter a valid name.</div>
      </div>

      <div class="mb-3">
        <label for="description">Description</label>

        <input
          v-model="org.description"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': org_submitted && !org.description,
            'is-valid': org.description,
          }"
          required
        />
        <div class="invalid-feedback">Please enter a valid description.</div>
      </div>

      <div class="mb-3">
        <label for="artist" class="form-label">Royalties Name Code</label>

        <input
          v-model="org.royalties_name_code"
          type="text"
          class="form-control"
          :class="{
            'is-invalid': org_submitted && !org.royalties_name_code,
            'is-valid': org.royalties_name_code,
          }"
          required
        />
        <div class="invalid-feedback">
          Please enter a valid royalties name code.
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label"> Type </label>

        <select
          v-model="org.type"
          class="form-select d-block w-100 m-1"
          :class="{
            'is-invalid': org_submitted && !org.type,
            'is-valid': org.type,
          }"
        >
          <option v-for="t in types" :key="t">
            {{ t }}
          </option>
        </select>
        <div class="invalid-feedback">Please select a valid org type.</div>
      </div>

      <div class="form-group mb-3">
        <label for="country" class="form-label"> Country</label>

        <select
          v-model="org.country"
          class="form-select d-block w-100 m-1"
          id="country"
          :class="{
            'is-invalid': org_submitted && !org.country,
            'is-valid': org.country && org.country,
          }"
        >
          <option disabled value>Choose...</option>
          <option>New Zealand</option>
          <option>Australia</option>
          <option disabled value>----------</option>
          <option v-for="country in countries" :key="country">
            {{ country }}
          </option>
        </select>
        <div class="invalid-feedback">Please select a valid country.</div>
      </div>

      <label for="release_title" class="form-label">Commission</label>
      <div class="input-group mb-3">
        <input
          v-model="org.commission"
          type="number"
          class="form-control"
          :class="{
            'is-invalid': org_submitted && !org.commission,
            'is-valid': org.commission,
          }"
        />
        <span class="input-group-text">%</span>
        <div class="invalid-feedback">Please enter a valid commission.</div>
      </div>

      <div class="mb-3">
        <label for="description" class="form-label">GST Number</label>

        <input
          v-model="org.gst_number"
          type="text"
          pattern="[0-9]*"
          inputmode="numeric"
          maxlength="9"
          class="form-control"
          :class="{
            'is-invalid': org_submitted && !org.gst_number,
            'is-valid': org.gst_number && org.gst_number.length > 7,
          }"
          placeholder="123-456-789"
          required
        />
        <div class="invalid-feedback">Please enter a valid GST number.</div>
      </div>
    </div>
    <div class="card-footer text-end">
      <button
        @click.prevent="cancel_create_org"
        class="btn btn-sm btn-outline-secondary me-2"
      >
        Cancel
      </button>
      <button
        @click.prevent="create_org"
        class="btn btn-sm btn-outline-success"
      >
        Save Org
      </button>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import Countries from "../assets/Countries.json";

export default {
  data() {
    return {
      org: { country: "New Zealand" },
      org_submitted: false,
      types: ["Label", "Manager", "Artist"],
      countries: Countries,
    };
  },

  methods: {
    add_org_to_fuga: async function () {
      console.log("add_org_to_fuga");
      const org = this.org;

      const org_record = {
        name: org.name,
      };

      const fuga_options = {
        record_type: "label",
        request_type: "create",
        data: org_record,
      };

      console.log(fuga_options);

      const fuga_record_upsert = fb.httpsCallable("fuga_record_upsert");
      const fuga_res = await fuga_record_upsert(fuga_options);

      console.log(fuga_res);

      const org_fuga_id = fuga_res.data.data.id;

      return org_fuga_id;
    },

    create_org: async function () {
      const org = this.org;

      // const org_fuga_id = await this.add_org_to_fuga();

      // console.log("org_fuga_id: " + org_fuga_id);

      if (!org.name || !org.royalties_name_code) {
        this.$toast.warning("Please complete required fields", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      let new_org_object = {
        name: (org.name && org.name.toString().trim().toUpperCase()) || "",
        description:
          (org.description && org.description.toString().trim()) || "",
        type: (org.type && org.type.toString().trim()) || "Label",
        country:
          (org.country && org.country.toString().trim()) || "New Zealand",
        commission: (org.commission && Number(org.commission)) || 0,
        royalties_name_code:
          (org.royalties_name_code &&
            org.royalties_name_code.toString().trim().toLowerCase()) ||
          "",
        royalties_id: (org.royalties_id && Number(org.royalties_id)) || "",
        gst_number: (org.gst_number && org.gst_number.toString()) || "",
        fuga_id: "",
        date_created: new Date(),
        is_active: true,
      };

      let new_org_record = await db.collection("orgs").add(new_org_object);
      console.log(new_org_record);
      console.log(new_org_record.id);
      return this.$emit("select_new_org", new_org_record.id);
    },

    cancel_create_org() {
      this.org = { country: "New Zealand" };
      this.org_submitted = false;
      return this.$emit("close_new_org");
    },
  },

  watch: {
    "org.name"(new_name, old_name) {
      if (new_name && new_name !== old_name) {
        console.log(new_name);
        this.org.royalties_name_code = new_name
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
          .substring(0, 12);
      }
    },
    deep: true,
  },
};
</script>

<style></style>
