var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basicLayout',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row m-3"},[_c('div',{staticClass:"col"},[(_vm.org.name)?_c('h2',[_vm._v(_vm._s(_vm.org.name))]):_c('h2',[_vm._v("New Org")])])]),_c('hr'),_c('div',{staticClass:"col-xl-6"},[_c('form',{staticClass:"needs-validation",attrs:{"novalidate":""}},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"release_title"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.name),expression:"org.name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && !_vm.org.name,
              'is-valid': _vm.org.name,
            },attrs:{"type":"text"},domProps:{"value":(_vm.org.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid name.")])]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.description),expression:"org.description"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && !_vm.org.description,
              'is-valid': _vm.org.description,
            },attrs:{"type":"text","required":""},domProps:{"value":(_vm.org.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid description. ")])]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Royalties ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.royalties_id),expression:"org.royalties_id"}],staticClass:"form-control",class:{
              'is-valid': _vm.org.royalties_id,
            },attrs:{"type":"text","required":""},domProps:{"value":(_vm.org.royalties_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "royalties_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid royalties id. ")])]),_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Royalties Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.royalties_name_code),expression:"org.royalties_name_code"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && !_vm.org.royalties_name_code,
              'is-valid': _vm.org.royalties_name_code,
            },attrs:{"type":"text","required":""},domProps:{"value":(_vm.org.royalties_name_code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "royalties_name_code", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid royalties code. ")])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(" Type ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.type),expression:"org.type"}],staticClass:"form-select d-block w-100 m-1",class:{
              'is-invalid': _vm.submitted && !_vm.org.type,
              'is-valid': _vm.org.type,
            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.org, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.types),function(org_type){return _c('option',{key:org_type},[_vm._v(" "+_vm._s(org_type)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please select a valid org type.")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"country"}},[_vm._v(" Country")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.country),expression:"org.country"}],staticClass:"form-select d-block w-100 m-1",class:{
              'is-invalid': _vm.submitted && !_vm.org.country,
              'is-valid': _vm.org.country && _vm.org.country,
            },attrs:{"id":"country"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.org, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Choose...")]),_c('option',[_vm._v("New Zealand")]),_c('option',[_vm._v("Australia")]),_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("----------")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country},[_vm._v(" "+_vm._s(country)+" ")])})],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please select a valid country.")])]),_c('label',{staticClass:"form-label",attrs:{"for":"release_title"}},[_vm._v("Commission")]),_c('div',{staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.commission),expression:"org.commission"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && !_vm.org.commission,
              'is-valid': _vm.org.commission,
            },attrs:{"type":"number"},domProps:{"value":(_vm.org.commission)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "commission", $event.target.value)}}}),_c('span',{staticClass:"input-group-text"},[_vm._v("%")]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid commission.")])]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("GST Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.org.gst_number),expression:"org.gst_number"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && !_vm.org.gst_number,
              'is-valid': _vm.org.gst_number && _vm.org.gst_number.length > 7,
            },attrs:{"type":"text","pattern":"[0-9]*","inputmode":"numeric","maxlength":"9","placeholder":"123-456-789","required":""},domProps:{"value":(_vm.org.gst_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.org, "gst_number", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please enter a valid GST number.")])]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.create_org.apply(null, arguments)}}},[_vm._v(" Save ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }