<template>
  <basicLayout>
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col">
          <h2 v-if="org.name">{{ org.name }}</h2>
          <h2 v-else>New Org</h2>
        </div>
      </div>
      <hr />

      <div class="col-xl-6">
        <form class="needs-validation" novalidate>
          <!-- <button @click.prevent="generate_upc">generate_upc</button> -->
          <div class="mb-3">
            <label for="release_title">Name</label>

            <input
              v-model="org.name"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !org.name,
                'is-valid': org.name,
              }"
            />
            <div class="invalid-feedback">Please enter a valid name.</div>
          </div>

          <div class="mb-3">
            <label for="description">Description</label>

            <input
              v-model="org.description"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !org.description,
                'is-valid': org.description,
              }"
              required
            />
            <div class="invalid-feedback">
              Please enter a valid description.
            </div>
          </div>

          <div class="mb-3">
            <label for="description">Royalties ID</label>

            <input
              v-model="org.royalties_id"
              type="text"
              class="form-control"
              :class="{
                'is-valid': org.royalties_id,
              }"
              required
            />
            <div class="invalid-feedback">
              Please enter a valid royalties id.
            </div>
          </div>

          <div class="mb-3">
            <label for="description">Royalties Code</label>

            <input
              v-model="org.royalties_name_code"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': submitted && !org.royalties_name_code,
                'is-valid': org.royalties_name_code,
              }"
              required
            />
            <div class="invalid-feedback">
              Please enter a valid royalties code.
            </div>
          </div>

          <div class="mb-3">
            <label> Type </label>

            <select
              v-model="org.type"
              class="form-select d-block w-100 m-1"
              :class="{
                'is-invalid': submitted && !org.type,
                'is-valid': org.type,
              }"
            >
              <option v-for="org_type in types" :key="org_type">
                {{ org_type }}
              </option>
            </select>
            <div class="invalid-feedback">Please select a valid org type.</div>
          </div>

          <div class="form-group mb-3">
            <label for="country" class="form-label"> Country</label>

            <select
              v-model="org.country"
              class="form-select d-block w-100 m-1"
              id="country"
              :class="{
                'is-invalid': submitted && !org.country,
                'is-valid': org.country && org.country,
              }"
            >
              <option disabled value>Choose...</option>
              <option>New Zealand</option>
              <option>Australia</option>
              <option disabled value>----------</option>
              <option v-for="country in countries" :key="country">
                {{ country }}
              </option>
            </select>
            <div class="invalid-feedback">Please select a valid country.</div>
          </div>

          <label for="release_title" class="form-label">Commission</label>
          <div class="input-group mb-3">
            <input
              v-model="org.commission"
              type="number"
              class="form-control"
              :class="{
                'is-invalid': submitted && !org.commission,
                'is-valid': org.commission,
              }"
            />
            <span class="input-group-text">%</span>
            <div class="invalid-feedback">Please enter a valid commission.</div>
          </div>

          <div class="mb-3">
            <label for="description" class="form-label">GST Number</label>
            <input
              v-model="org.gst_number"
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="9"
              class="form-control"
              :class="{
                'is-invalid': submitted && !org.gst_number,
                'is-valid': org.gst_number && org.gst_number.length > 7,
              }"
              placeholder="123-456-789"
              required
            />
            <div class="invalid-feedback">Please enter a valid GST number.</div>
          </div>

          <button
            @click.prevent="create_org"
            type="submit"
            class="btn btn-secondary"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </basicLayout>
</template>

<style></style>

<script>
import basicLayout from "@/components/basicLayout";
import { db, fb } from "@/firebaseConfig";
import Countries from "../assets/Countries.json";

export default {
  name: "createOrg",
  data: function () {
    return {
      org: { country: "New Zealand" },
      countries: Countries,
      types: ["Label", "Manager", "Artist"],
      submitted: false,
    };
  },

  components: {
    basicLayout,
  },

  methods: {
    add_org_to_fuga: async function () {
      console.log("add_org_to_fuga");
      const org = this.org;

      const org_record = {
        name: org.name,
      };

      const fuga_options = {
        record_type: "label",
        request_type: "create",
        data: org_record,
      };

      console.log(fuga_options);

      const fuga_record_upsert = fb.httpsCallable("fuga_record_upsert");
      const fuga_res = await fuga_record_upsert(fuga_options);

      console.log(fuga_res);

      const org_fuga_id = fuga_res.data.data.id;

      return org_fuga_id;
    },

    create_org: async function () {
      this.submitted = true;
      let org = this.org;

      // const org_fuga_id = await this.add_org_to_fuga();

      // console.log("org_fuga_id: " + org_fuga_id);

      if (!org.name || !org.royalties_name_code) {
        this.$toast.warning("Please complete required fields", {
          position: "top-right",
          timeout: 5000,
        });

        return;
      }

      let new_org_object = {
        name: (org.name && org.name.toString().trim().toUpperCase()) || "",
        description:
          (org.description && org.description.toString().trim()) || "",
        type: (org.type && org.type.toString().trim()) || "Label",
        country:
          (org.country && org.country.toString().trim()) || "New Zealand",
        commission: (org.commission && Number(org.commission)) || 0,
        royalties_name_code:
          (org.royalties_name_code &&
            org.royalties_name_code.toString().trim().toLowerCase()) ||
          "",
        royalties_id: (org.royalties_id && Number(org.royalties_id)) || "",
        gst_number: (org.gst_number && org.gst_number.toString()) || "",
        date_created: new Date(),
        fuga_id: "",
        is_active: true,
      };

      db.collection("orgs").add(new_org_object);
      this.$router.push("/orgs");
    },
  },

  watch: {
    // whenever question changes, this function will run
    "org.name"(newQuestion, oldQuestion) {
      if (newQuestion !== oldQuestion) {
        console.log(newQuestion);
        this.org.royalties_name_code = newQuestion
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
          .substring(0, 12);
      }
    },
    deep: true,
  },
};
</script>
